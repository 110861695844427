//
// Basic layout
//

html {
  /* always show a vertical scrollbar, avoid "jumping" at long pages */
  overflow-y: scroll;
}

html body {
  background: $bg-body;
}

img {
  height: auto;
  max-width: 100%;
}

//
// Header
//

#enova-header-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

#mainnavigation-wrapper {
  margin-bottom: 2rem;
}

//
// Footer
//

#portal-footer-wrapper {
  background-color: transparent;
  color: #cccccc;
}

#portal-footer .row + .site-actions {
  border: 0;
}

#portal-footer .row.site-actions {
  margin-top: 30px;
  margin-bottom: 30px;
}

#portal-footer-wrapper .site-actions a {
  color: $color-gray;

  &:hover {
    color: $color-green-light;
  }
}

#portal-footer .portlet-static-logos .headline {
  display: none;
}

#portal-footer .portlet-static-logos table {
  border: 0;
  width: auto;
  margin: 0 auto;

  & td {
    border: 0;
    text-align: center;
    vertical-align: middle;
  }

  & img {
    margin: 0 10px 30px 10px;
  }
}
