//
// Top navigation
//

#portal-globalnav {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  width: 100%;
}

#portal-globalnav .nav-link {
  font-weight: 700;
  font-size: 1.2rem;
  border-bottom: 2px solid transparent;
}

#portal-globalnav .current .nav-link {
  border-bottom-color: $color-green-light;
}
