//
// Views
//

.template-full_view #content-core .item {
  padding: 1rem 0;
}

.template-full_view #content-core .item + .item {
  border-top: 1px solid $color-green-light;
}

.template-full_view #content-core .item h1 {
  border-bottom: 0;
  font-size: 1.1rem;
}

.template-full_view #content-core .item h1 a {
  text-decoration: none;
}

.template-full_view #content-core #section-item {
  margin-bottom: 0;
}
