//
// Portlets
//

#portal-column-two {
  border-left: 2px solid $color-green-dark;
  padding-left: 2rem;
}

#portal-column-two .portlet {
  border: 0;
  margin-bottom: 2rem;
}

#portal-column-two .portlet a {
  text-decoration: none;
}

#portal-column-two .portlet .card-header {
  color: $color-green-dark;
  font-weight: 700;
  font-size: 1.5rem;
  background: transparent;
  border-bottom: 0;
}

#portal-column-two .portletNews .card-footer {
  display: none;
}

#portal-column-two .portletItemDetails {
  color: #666;
}
